import { useState, useEffect } from 'react';
import { sanitize } from 'dompurify';
import { IconButton, MenuItem, Collapse } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import PropTypes from 'prop-types';
import { useQuery, useScroll } from '@hooks/index';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from "react-hook-form"

import { CLATooltip } from "@ais/components"
import {
	FIELD_TYPES,
	RULES,
	DRAG_TYPES,
	INTERNAL_CONTROLS_CONSTANTS,
	INTERNAL_CONTROLS_FORM_TYPE_ID
} from '@ais/constants';
import { CLAActionEllipsis } from '@ais/forms';
import { useProjectFormInstanceProvider } from '@providers';
import {
	ACTION_ELLIPSIS,
	ACTION_ELLIPSIS_TYPES,
	AUDIT_SCOPING_SECTION,
	COMMUNICATION_OF_SIGNIFICANT_MATTERS
} from '@constants/forms';

import { CollapseIconComponent, ExpandIconComponent } from "@ais/assets";
import { ReactComponent as RiskAlertIcon } from "@assets/icon_risk_alert.svg";
import { ReactComponent as DeficiencyInfoIcon } from "@assets/form_deficiency_info_icon.svg";
import { DeficiencyModal } from '@components/FormView/actions';
import { AddQuestion, AddRisk, AddRiskFactor } from '@components/FormView/actions';
import string from "@utilities/stringHelper";
import { useAccountAssertionLevelRiskState } from '../../components/AccountAssertionLevelRisk/AccountAssertionLevelRiskContext';

import styles from '@components/FormView/FormView.module.css';
import { useProjectRiskDeficienciesContext } from '@contexts/ProjectRiskDeficienciesContext';

const menuItems = [
	{
		label: ACTION_ELLIPSIS.ADD_QUESTION,
		type: ACTION_ELLIPSIS_TYPES.ADD_QUESTION,
	},
	{
		label: ACTION_ELLIPSIS.ADD_RISK,
		type: ACTION_ELLIPSIS_TYPES.ADD_RISK,
	},
	{
		label: ACTION_ELLIPSIS.ADD_RISK_FACTOR,
		type: ACTION_ELLIPSIS_TYPES.ADD_RISK_FACTOR,
	},
	{
		label: ACTION_ELLIPSIS.ADD_DEFICIENCY,
		type: ACTION_ELLIPSIS_TYPES.ADD_DEFICIENCY,
	},
	{
		label: ACTION_ELLIPSIS.EDIT_MOVE_QUESTION,
		type: ACTION_ELLIPSIS_TYPES.EDIT_MOVE_QUESTION,
	}
];

const customSectionMenuItems = [
	{
		label: ACTION_ELLIPSIS.ADD_RISK,
		type: ACTION_ELLIPSIS_TYPES.ADD_RISK,
	},
	{
		label: ACTION_ELLIPSIS.ADD_RISK_FACTOR,
		type: ACTION_ELLIPSIS_TYPES.ADD_RISK_FACTOR,
	},
	{
		label: ACTION_ELLIPSIS.ADD_DEFICIENCY,
		type: ACTION_ELLIPSIS_TYPES.ADD_DEFICIENCY,
	}
];

const deficiencySectionMenuItems = [
	{
		label: ACTION_ELLIPSIS.ADD_RISK,
		type: ACTION_ELLIPSIS_TYPES.ADD_RISK,
	},
	{
		label: ACTION_ELLIPSIS.ADD_RISK_FACTOR,
		type: ACTION_ELLIPSIS_TYPES.ADD_RISK_FACTOR,
	},
	{
		label: ACTION_ELLIPSIS.ADD_DEFICIENCY,
		type: ACTION_ELLIPSIS_TYPES.ADD_DEFICIENCY,
	}
];

export const VFSection = (props) => {
	const {
		children,
		section,
		projectId,
		projectFormId,
		isInstance,
		onEditMenuClick,
		onBlur,
		listenEvent,
		methodologyIndustries,
		headerRight,
		formName,
		disabled,
		units,
		formTypeId,
		isFinancialStatementLevelRisk,
		isAssertionLevelRisk,
		frameworks = [],
		performanceStandards = []
	} = props;
	const query = useQuery();
	const navigate = useNavigate();
	const sectionId = query.get('sectionId');
	const questionIds = query.get('questionIds');
	const hasSingleQuestion = !questionIds || questionIds.split(',').length <= 1;
	const shouldScrollToSection = string.toLowerCaseTrim(section.id) === string.toLowerCaseTrim(sectionId) && hasSingleQuestion;

	// ? Section rules states
	const [rules, setRules] = useState(section?.rules || undefined);
	const [results, setResults] = useState([]);
	const [anyResults, setAnyResults] = useState(false);
	const [allResults, setAllResults] = useState(false);
	const [hidden, setHidden] = useState(false);

	// ? Context menu states
	const [type, setType] = useState('');
	const [actionDialog, setActionDialog] = useState(null);
	const [visible, setVisible] = useState(false);
	const [executeScroll, elRef] = useScroll();
	const { projectFormRisks, projectFormDeficiencies } = useProjectRiskDeficienciesContext()
	const { actions: { updateFinancialStatementLevelRiskToggle, updateIsFinancialStatementExpanded, updateIsAssertionLevelRiskExpanded } } = useProjectFormInstanceProvider();
	const { state, expandAll, collapseAll, updateChildExpanded, isChildExpanded } = useAccountAssertionLevelRiskState();
	const { allExpanded } = state;
	const isExpanded = isChildExpanded(section.id);
	const shouldShowCollapsed = () => {
		if (shouldScrollToSection) {
			return false;
		} else {
			return section?.showCollapsed;
		}
	};

	const isPreliminaryAuditScoping = section.id === AUDIT_SCOPING_SECTION.id;
	const showCollapsed = shouldShowCollapsed();
	const sectionType = section?.sectionType ?? section?.type;
	const isAssertionLevelRiskSection = isInstance &&
		sectionType === 'CUSTOM' &&
		section?.fields?.flatMap(i => i).some(f => f.type === FIELD_TYPES.ACCOUNT_AND_ASSERTION_LEVEL_RISK);
	const isDeficiencySection = isInstance &&
		sectionType === 'CUSTOM' &&
		section?.fields?.flatMap(i => i)
			.some(f => f.type === FIELD_TYPES.EVALUATION_OF_DEFICIENCIES_IN_INTERNAL_CONTROL ||
				f.type === FIELD_TYPES.COMMUNICATION_OF_DEFICIENCIES_IN_INTERNAL_CONTROL);
	const sectionCSS = (sectionType === 'SECTION_BLANK') ? styles['vf-section-blank'] : '';
	const formIsInternalControl = formTypeId === INTERNAL_CONTROLS_FORM_TYPE_ID;
	const isViewFormIntControlInstructions = !isInstance && formIsInternalControl && sectionType === 'SECTION_BLANK';
	const shouldRemovePadding = isViewFormIntControlInstructions
		? styles['view-form-internal-control-section-blank']
		: '';
	const ellipsisMenu = sectionType === 'CUSTOM' || isPreliminaryAuditScoping ?
		(isDeficiencySection ? deficiencySectionMenuItems : customSectionMenuItems) : menuItems;
	const shouldShowSectionHeader = [
		DRAG_TYPES.SECTION,
		DRAG_TYPES.SECTION_CUSTOM,
		DRAG_TYPES.SECTION_SEMICUSTOM,
		DRAG_TYPES.ADD_PROCEDURE,
		DRAG_TYPES.PROCEDURE
	].includes(sectionType) || isPreliminaryAuditScoping;
	const shouldShowEllipsis =
		!isAssertionLevelRiskSection &&
		section?.showContextMenu &&
		!(formIsInternalControl && sectionType === DRAG_TYPES.SECTION_SEMICUSTOM);

	const formCanvas = useFormContext();
	useEffect(() => {
		setRules(section?.rules);
	}, [section]);
	useEffect(() => {
		updateChildExpanded(section.id, !section?.showCollapsed)
	}, [])
	useEffect(
		function filterCriterias() {
			if (rules?.criterias?.length) {
				const { criterias } = rules;
				const results = [];
				criterias.forEach((criteria) => {
					const { criteriaType, questionId, any, isEqual, value } = criteria;
					if (criteriaType === RULES.CRITERIA_TYPES.QUESTION) {
						const _value = formCanvas.getValues(questionId);
						const formValue = Array.isArray(_value)
							? _value.filter(
								(value) => value !== undefined && value !== ''
							)
							: [_value].filter(
								(value) => value !== undefined && value !== ''
							);
						if (any) {
							if (formValue.length) results.push(true);
							else results.push(false);
						} else {
							if (isEqual === RULES.EQUALITY.IS)
								results.push(
									formValue.length === value.length &&
									formValue.every((_formValue) => value.includes(_formValue))
								);
							else if (isEqual === RULES.EQUALITY.IS_NOT)
								results.push(
									!formValue.every((_formValue) => value.includes(_formValue))
								);
							else if (isEqual === RULES.EQUALITY.ANY)
								results.push(
									formValue.some((_formValue) => value.includes(_formValue))
								);
						}
					} else if (
						isInstance &&
						criteriaType === RULES.CRITERIA_TYPES.INDUSTRY
					) {
						if (isEqual === RULES.EQUALITY.IS)
							results.push(
								value.every((mi) => methodologyIndustries.includes(mi))
							);
						else if (isEqual === RULES.EQUALITY.IS_NOT)
							results.push(
								!methodologyIndustries.some((mi) => value.includes(mi))
							);
						else if (isEqual === RULES.EQUALITY.ANY) {
							results.push(
								methodologyIndustries.some((mi) => value.includes(mi))
							);
						}
					} else if (
						isInstance &&
						criteriaType === RULES.CRITERIA_TYPES.REPORTING_FRAMEWORK
					) {
						if (isEqual === RULES.EQUALITY.IS)
							results.push(
								value.every((f) => frameworks.includes(f))
							);
						else if (isEqual === RULES.EQUALITY.IS_NOT)
							results.push(
								!frameworks.some((f) => value.includes(f))
							);
						else if (isEqual === RULES.EQUALITY.ANY) {
							results.push(
								frameworks.some((f) => value.includes(f))
							);
						}
					} else if (
						isInstance &&
						criteriaType === RULES.CRITERIA_TYPES.PERFORMANCE_STANDARDS
					) {
						if (isEqual === RULES.EQUALITY.IS)
							results.push(
								value.every((ps) => performanceStandards.includes(ps))
							);
						else if (isEqual === RULES.EQUALITY.IS_NOT)
							results.push(
								!performanceStandards.some((ps) => value.includes(ps))
							);
						else if (isEqual === RULES.EQUALITY.ANY) {
							results.push(
								performanceStandards.some((ps) => value.includes(ps))
							);
						}
					}
				});
				setResults(results);
			}
		},
		[formCanvas]
	);

	useEffect(
		function identifyMatchBasedOnResults() {
			if (rules) {
				if (rules?.match === RULES.MATCH.ANY)
					setAnyResults(results.some((result) => result === true));
				else if (rules?.match === RULES.MATCH.ALL)
					setAllResults(results.every((result) => result === true));
			}
		},
		[results]
	);

	useEffect(() => {
		if (results.length) {
			if (rules?.match === RULES.MATCH.ANY) {
				if (rules?.behavior === RULES.BEHAVIOR.SHOW) {
					setHidden(false);
				} else if (rules?.behavior === RULES.BEHAVIOR.HIDE) {
					if (anyResults) setHidden(true);
					else setHidden(false);
				}
			} else if (rules?.match === RULES.MATCH.ALL) {
				if (rules?.behavior === RULES.BEHAVIOR.SHOW) {
					if (allResults) setHidden(false);
					else setHidden(true);
				} else if (rules?.behavior === RULES.BEHAVIOR.HIDE) {
					if (allResults) setHidden(true);
					else setHidden(false);
				}
			}
		} else setHidden(false);
	}, [results, anyResults, allResults]);

	useEffect(() => {
		if (isInstance) {
			let toRender = null;
			switch (type) {
				case ACTION_ELLIPSIS_TYPES.ADD_QUESTION:
					toRender = (
						<AddQuestion
							section={section}
							projectFormId={projectFormId}
							visible={visible}
							setVisible={setVisible}
						/>
					);
					break;
				case ACTION_ELLIPSIS_TYPES.ADD_RISK:
					toRender = (
						<AddRisk
							section={section}
							visible={visible}
							setVisible={setVisible}
							formSectionId={section.id}
							formName={formName}
							isEdit={false}
							showWorkPaperReference={false}
							onSuccessfulSave={() => updateFinancialStatementLevelRiskToggle()}
						/>
					);
					break;
				case ACTION_ELLIPSIS_TYPES.EDIT_MOVE_QUESTION:
					onEditMenuClick(section.id);
					setType('');
					break;
				case ACTION_ELLIPSIS_TYPES.ADD_RISK_FACTOR:
					toRender = (
						<AddRiskFactor
							section={section}
							formName={formName}
							visible={visible}
							setVisible={setVisible}
							projectId={projectId}
							projectFormId={projectFormId}
							isEdit={false}
						/>
					);
					break;
				case ACTION_ELLIPSIS_TYPES.ADD_DEFICIENCY:
					toRender = (
						<DeficiencyModal
							section={section}
							visible={visible}
							setVisible={setVisible}
							formName={formName}
							units={units}
						/>
					);
					break;
				default:
			}
			if (toRender) setActionDialog(toRender);
		}
	}, [type, visible]);

	useEffect(() => {
		if (shouldScrollToSection) {
			executeScroll();
		}
	}, []);

	useEffect(() => {
		updateChildExpanded(section.id, !showCollapsed);
	}, [showCollapsed, isFinancialStatementLevelRisk]);

	useEffect(() => {
		if (!!isFinancialStatementLevelRisk) {
			updateIsFinancialStatementExpanded(isExpanded)
		}

		if (!!isAssertionLevelRisk) {
			updateIsAssertionLevelRiskExpanded(isExpanded)
		}
	}, [isExpanded, isFinancialStatementLevelRisk, isAssertionLevelRisk])

	const onClickAway = () => {
		if (onBlur) {
			onBlur(section.id);
		}
	};

	const redirectToRiskSummary = () => {
		navigate(`/project-management/${projectId}/risk-summary`);
	};

	const redirectToDeficiencySummary = () => {
		navigate(`/project-management/${projectId}/deficiency-summary`);
	};

	return (
		<ClickAwayListener
			mouseEvent={isInstance && listenEvent ? 'onClick' : false}
			onClickAway={onClickAway}
		>
			<div
				className={styles['vf-section-wrapper']}
				style={{ display: hidden ? 'none' : 'flex' }}
			>
				<div ref={elRef} className={`${styles['vf-section']} ${shouldRemovePadding} ${sectionCSS}`}>
					{shouldShowSectionHeader && (
						<div className={styles['section-header-wrapper']}>
							{(!isPreliminaryAuditScoping && !isDeficiencySection) &&
								<IconButton onClick={() => updateChildExpanded(section.id, !isExpanded)}>
									{isExpanded ? <ExpandIconComponent /> : <CollapseIconComponent />}
								</IconButton>}
							<div className={!isDeficiencySection ? styles['section-header'] : styles['section-header-static']}>
								{section?.title}
								{section?.tooltip && (
									<CLATooltip
										title={
											<div
												className="ql-editor"
												dangerouslySetInnerHTML={{
													__html: sanitize(section.tooltip, {
														ADD_ATTR: ['target'],
													}),
												}}
											/>
										}
										placement="bottom"
									>
										<HelpIcon style={{ fontSize: '18px' }} />
									</CLATooltip>
								)}
							</div>
							{headerRight && headerRight()}
							{(isInstance && projectFormRisks.includes(section.id.toUpperCase())) &&
								<RiskAlertIcon style={{ cursor: "pointer" }} onClick={() => redirectToRiskSummary()} />}
							{(isInstance && projectFormDeficiencies.includes(section.id.toUpperCase())) &&
								<DeficiencyInfoIcon style={{ cursor: "pointer", width: '25px', height: '25px' }} onClick={() => redirectToDeficiencySummary()} />}
							{isAssertionLevelRiskSection && <a href={`#${allExpanded ? 'expand' : 'collapse'}`} className={styles.expand_all_link} onClick={() => { allExpanded ? collapseAll() : expandAll() }}>{allExpanded ? 'Collapse' : 'Expand'} All</a>}
							{shouldShowEllipsis && (
								<CLAActionEllipsis>
									{(handleClose) =>
										ellipsisMenu.map((item) => (
											<MenuItem
												key={item.type}
												onClick={() => {
													if (isInstance) {
														setType(item.type);
														setVisible(true);
													}
													handleClose();
												}}
												variant="ellipsis-menu"
												disabled={disabled}
											>
												{item.label}
											</MenuItem>
										))
									}
								</CLAActionEllipsis>
							)}
						</div>
					)}
					{section?.description && (
						<div style={{ fontSize: '14px', color: '#595959' }}>
							<div
								className="ql-editor"
								dangerouslySetInnerHTML={{
									__html: sanitize(section.description, {
										ADD_ATTR: ['target'],
									}),
								}}
							/>
						</div>
					)}
					<Collapse in={isExpanded} collapsedSize={0}>
						{!isInstance &&
							formIsInternalControl &&
							sectionType === 'CUSTOM' &&
							section?.title === COMMUNICATION_OF_SIGNIFICANT_MATTERS.SECTION_TITLE ? (
							<div className={styles['vf-section-wrapper--comm-of-sig-matters']}>
								<div
									className={styles['vf-section-placeholder']}
								>
									{INTERNAL_CONTROLS_CONSTANTS.CUSTOM_COMPONENT_ENABLED}
								</div>
							</div>

						) : (
							children
						)}
					</Collapse>
				</div>
				{actionDialog}
			</div>
		</ClickAwayListener>
	);
};

VFSection.propTypes = {
	children: PropTypes.node,
	section: PropTypes.object,
	projectFormId: PropTypes.any,
	projectFormName: PropTypes.string,
	isInstance: PropTypes.bool,
	onEditMenuClick: PropTypes.func,
	onBlur: PropTypes.func,
	methodologyIndustries: PropTypes.array,
	formName: PropTypes.string,
	disabled: PropTypes.bool,
	units: PropTypes.array,
};

VFSection.defaultProps = {
	isInstance: false,
	methodologyIndustries: [],
	formName: '',
	disabled: false,
	units: [],
};
